<template>
<div style="background: #F2F3F8; width: 100%;height: 100%;padding-top: 20px;">

  <div class="personalCenterContainer">
    <div class="titles">
      <i class="el-icon-arrow-left" style="font-size: 18px;cursor:pointer;" @click="goBack"></i>
      员工账号管理
    </div>
      <div style="background: #F2F3F8;height: 20px;width:100%">

    </div>
    <div class="staffAccountContainer">
      <div class="createAccount">
        <el-row :gutter="20">
          <el-col :span="24">
            <span>生成员工账号：</span>
            <el-input class="staffAccountInput" v-model="cellphone" placeholder="请输入手机号码"></el-input>
            <el-button type="primary" @click="createInviteCode()">生成邀请码</el-button>
            <span class="inviteCodeTime">邀请码将发送到员工手机号中</span>
            <!-- <span class="inviteCodeTime">邀请码有效时间24小时</span> -->
          </el-col>
        </el-row>
      </div>
      <div class="tableList">
        <el-table :data="tableDatas">
          <el-table-column type="index" label="序号" width="70"></el-table-column>
          <el-table-column prop="user_name" label="姓名"></el-table-column>
          <el-table-column prop="cellphone" label="手机号"></el-table-column>
          <!-- <el-table-column prop="roleName" label="角色"></el-table-column> -->
          <el-table-column label="操作" width="260">
            <template slot-scope="scope">
              <div v-if="scope.row.type != 'R'">
                <!-- <el-button type="warning" size="mini" @click="showConnectDialog(scope.row)">关联角色</el-button> -->
                <el-button v-if="scope.row.user_status === 'D'" type="success" size="mini" @click="enableItem(scope.row)">启用</el-button>
                <el-button v-if="scope.row.user_status === 'N'" type="danger" size="mini" @click="disableItem(scope.row)">禁用</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
        ></el-pagination>
      </div>
      <!-- 关联角色 -->
      <el-dialog
      v-dialogDrag
        title="关联角色"
        :close-on-click-modal="false"
        :visible.sync="connectDialogVisible"
        width="400px"
        :before-close="handleClose">
        <div class="connectBox">
          <el-form ref="form" :model="form" :rules="rules" label-position="top">
            <el-form-item label="关联角色" prop="connectId">
              <el-select class="width-100" v-model="form.connectId" placeholder="请选择想要关联的角色">
                <el-option
                  v-for="item in roleOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="connectDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmConnect()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</div>
</template>
<script>
export default {
  data() {
    return {
      userMes: '',
      cellphone: null,
      current: 1,
      pageSize: 10,
      totalPage: null,
      tableDatas: [],
      roleOptions: [],
      form: {
        connectId: null
      },
      rules: {
        connectId: [
          { required: true, message: '请选择关联角色', trigger: 'blur' }
        ]
      },
      connectDialogVisible: false,
      connectFirmId: null,
      connectRoleId: null
    };
  },
  mounted() {
  this.userMes = this.$store.state.common.sessionInfo;
    this.getDatas();
  },
  methods: {
    goBack() {
      this.$router.push('/userOrSafety')
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    getAllRole() {
      protocolFwd.param_queryAllRoleInfo.param.page = 0;
      protocolFwd.param_queryAllRoleInfo.param.size = 1000;
      protocolFwd.param_queryAllRoleInfo.param.roleTypes = [3];
      http.postFrontZYQ(protocolFwd.param_queryAllRoleInfo).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.roleOptions = value.content;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    createInviteCode() {
      if (this.cellphone && this.cellphone.match(/^1\d{10}$/)) {
        protocolFwd.param_generateCode.param.cellphone = this.cellphone;
        protocolFwd.param_generateCode.param.companyId = 8;
        http.postFrontZYQ(protocolFwd.param_generateCode).then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.cellphone = null;
            this.$EL_MESSAGE(message);
          } else {
            this.$EL_MESSAGE(message);
          }
        });
      } else {
        this.$EL_MESSAGE('请输入正确格式的手机号码');
      }
    },
    getDatas() {
      protocolFwd.param_queryFirmForEnterprise.param.page = this.current;
      protocolFwd.param_queryFirmForEnterprise.param.size = this.pageSize;
      http.postFrontZYQ(protocolFwd.param_queryFirmForEnterprise).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.tableDatas = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    showConnectDialog(item) {
      this.getAllRole();
      this.connectFirmId = item.firm_id;
      this.connectDialogVisible = true;
    },
    confirmConnect(item) {
      this.$refs["form"].validate(valid => {
        if (valid) {
          protocolFwd.param_addUserToRoleForUser.param.firmId = this.connectFirmId;
          protocolFwd.param_addUserToRoleForUser.param.roleIds = [].concat(this.form.connectId);
          http.postFrontZYQ(protocolFwd.param_addUserToRoleForUser).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.handleClose();
              this.getDatas();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        }
      });
    },
    enableItem(item) {
      console.log(item, 999)
      this.$confirm('请确认是否启用该员工？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        protocolFwd.param_updateCertificationStatus.param.userId = item.user_id;
        protocolFwd.param_updateCertificationStatus.param.userStr = this.userMes.userId
        protocolFwd.param_updateCertificationStatus.param.sessionStr =  this.userMes.sessionStr
        protocolFwd.param_updateCertificationStatus.param.userStatus = "N";
        http.postFrontZYQ(protocolFwd.param_updateCertificationStatus).then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.$EL_MESSAGE(message);
            this.getDatas();
          } else {
            this.$EL_MESSAGE(message);
          }
        });
      }).catch(() => {

      });
    },
    disableItem(item) {
      console.log(item, 999)
      this.$confirm('请确认是否禁用该员工？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        protocolFwd.param_updateCertificationStatus.param.userStr = this.userMes.userId
        protocolFwd.param_updateCertificationStatus.param.sessionStr =  this.userMes.sessionStr
        protocolFwd.param_updateCertificationStatus.param.userId = item.user_id;
        protocolFwd.param_updateCertificationStatus.param.userStatus = "D";
        http.postFrontZYQ(protocolFwd.param_updateCertificationStatus).then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.$EL_MESSAGE(message);
            this.getDatas();
          } else {
            this.$EL_MESSAGE(message);
          }
        });
      }).catch(() => {

      });
    },
    handleClose() {
      this.form = {
        connectId: null
      }
      this.$refs['form'].resetFields();
      this.connectDialogVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.personalCenterContainer {
  width: 1200px;
  margin: 0 auto;
  line-height: 32px;
  background-color: #fff;
  height: 100%;
  // margin-top: 20px;
}
.personalCenterContainer .personalCenterTitle {
  font-size: 16px;
  // border-bottom: 1px solid #eeeeee;
  padding: 15px;
}
.personalCenterContainer .personalCenterTitle span {
  display: inline-block;
  padding: 0 15px;
  border-bottom: 2px solid $commonThemeColor;
}
.staffAccountContainer {
  padding: 10px 20px;
}
.personalCenterContainer .createAccount {
  margin: 10px 0;
}
.personalCenterContainer .createAccount .staffAccountInput {
  width: auto;
  margin-right: 15px;
}
.personalCenterContainer .createAccount .inviteCodeTime {
  margin-left: 15px;
  color: #9999a2;
}
.personalCenterContainer .pagination {
  margin-top: 15px;
}
.titles {
  padding: 15px;
  font-size: 18px;
}
</style>
